import React from 'react';

function Legal() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h3 className="h2 mb-4">Legal</h3>
            <h4>The Melevco project is supported by Oman Al-Hashmi Law</h4>
          </div>

          {/* Team members */}
          <div className="sm:flex sm:flex-wrap sm:justify-center -my-4 sm:-my-8 sm:-mx-3">

            {/* 1st member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3">

            </div>

            {/* 2nd member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3">
              <div className="flex flex-col items-center" data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeIn" data-sal-delay="300">
                <img className="rounded-full mb-4" src={require('../images/Omar-Al-Hashmi.jpg')} width="120" height="120" alt="Omar Al Hashmi" />
                <h4 className="text-xl font-medium mb-1">Omar Al Hashmi</h4>
                <p className="block text text-center">Managing Partner</p>
              </div>
            </div>


            {/* 3rd member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3">
              <div className="flex flex-col items-center" data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeIn" data-sal-delay="450">
                <img className="rounded-full mb-4" src={require('../images/dhanapillai1.jpg')} width="120" height="120" alt="Dhana Pillai" />
                <h4 className="text-xl font-medium mb-1">Dhana Pillai</h4>
                <p className="block text text-center">Head of Real Estate, Tax and Project Finance</p>
              </div>
            </div>

            {/* 4th member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3">

            </div>

          </div>
        </div>
        <div className="pt-0 pb-12 border-b border-gray-800">
          <div className="sm:w-1/1 md:w-1/1 lg:w-1/1 sm:px-3">
            <div className="flex flex-col items-center" data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeIn" data-sal-delay="600">
              <a className="block text-center text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="https://www.ohlaw.net" target="_blank" rel="noreferrer">
                <img className="mb-4 mx-auto" src={require('../images/al-hashmi-logo-white.jpg')} width="80" height="80" alt="Al-Hashmi Law" />
                <p className="text-center">www.ohlaw.net</p>
              </a>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
}

export default Legal;
