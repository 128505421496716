import React from 'react';

function Target() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20" data-sal="fade" data-sal-duration="1400" data-sal-easing="easeIn" data-sal-delay="1000">
            <h3 className="h2">Don’t limit your challenges – challenge your limits!</h3>
          </div>

          {/* Items */}
          <div className="grid gap-20">

            {/* Item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">

              {/* Image */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl" data-sal="slide-right" data-sal-duration="1400" data-sal-easing="easeIn">
                <img className="mx-auto md:max-w-none" src={require('../images/features-02.jpg')} alt="" width="540" height="520" />
              </div>

              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-sal="slide-left" data-sal-duration="500" data-sal-easing="easeIn">
                <div className="md:pl-4 lg:pl-12 xl:pl-16">
                  <div className="mt-6">
                    <p className="text-lg text-gray-400">Melevco has recognized the challenges which prevail globally, in the Middle East and in Oman.  COVID 19, low oil prices and general low risk appetite of local banks.</p>
                  </div>
                  <div className="mt-6">
                    <p className="text-lg text-gray-400">Ready to fund working capital requirements for contractors with long term relationships PDO, IOS or government contracts in Oman.</p>
                  </div>
                  <div className="mt-6">
                    <p className="text-lg text-gray-400">To address these challenges Melevco is deploying 500 Million USD to mainly SME’s in Oman to support the oil and gas industry, and to other key industries.</p>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default Target;
