import React from 'react';

function Team() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-b border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h3 className="h2 mb-4">Our team</h3>
          </div>

          {/* Team members */}
          <div className="sm:flex sm:flex-wrap sm:justify-center -my-4 sm:-my-8 sm:-mx-3">

            {/* 1st member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3">
              <div className="flex flex-col items-center" data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeIn" data-sal-delay="150">
                <img className="rounded-full mb-4" src={require('../images/duijndam.jpg')} width="120" height="120" alt="Bart Duijndam" />
                <h4 className="text-xl font-medium mb-1">Bart Duijndam</h4>
                <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="mai&#108;to&#58;%6&#50;a&#114;t&#64;mel&#101;vco%2E&#99;om">&#98;art&#64;mel&#101;vc&#111;&#46;&#99;&#111;&#109;</a>
                <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="tel:+33 6 07 93 40 19">+33 6 07 93 40 19</a>
              </div>
            </div>

            {/* 2nd member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3">
              <div className="flex flex-col items-center" data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeIn" data-sal-delay="300">
                <img className="rounded-full mb-4" src={require('../images/johanssen.jpg')} width="120" height="120" alt="Nils Johannsen" />
                <h4 className="text-xl font-medium mb-1">Nils Johannsen</h4>
                <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="&#109;ai&#108;to&#58;n%69ls&#64;me&#108;&#37;65vco%2E&#99;&#111;m">&#110;&#105;ls&#64;melevc&#111;&#46;co&#109;</a>
                <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="tel:+971 50 554 1195">+971-50-55 411 94</a>
              </div>
            </div>


            {/* 3rd member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3">
              <div className="flex flex-col items-center" data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeIn" data-sal-delay="450">
                <img className="rounded-full mb-4" src={require('../images/al-mahrouqi.jpg')} width="120" height="120" alt="Rashid Al Mahrouqi" />
                <h4 className="text-xl font-medium mb-1">Rashid Al Mahrouqi</h4>
                <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="&#109;ail&#116;o&#58;r&#97;s%68i%&#54;4%40%6De%6Cevc&#37;6F%&#50;Eco&#109;">ra&#115;h&#105;d&#64;melevco&#46;co&#109;</a>
                <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="tel:+968-9198 8882">+968-9198 8882</a>
              </div>
            </div>

            {/* 4th member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3">
              <div className="flex flex-col items-center" data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeIn" data-sal-delay="600">
                <img className="rounded-full mb-4" src={require('../images/Mohamed-Al-Maskari.jpg')} width="120" height="120" alt="Mohamed Al Maskari" />
                <h4 className="text-xl font-medium mb-1">Mohamed Al Maskari</h4>
                <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="mailt&#111;&#58;&#37;6&#68;o%68amed&#64;&#109;&#101;l&#101;v%63%&#54;&#70;&#46;c%6&#70;%&#54;D">moham&#101;d&#64;melevc&#111;&#46;com</a>
                <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="+968-9932 7127">+968-9932 7127</a>
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default Team;
