import React from 'react';
import HeroHome from '../partials/HeroHome';
import PageIllustration from '../partials/PageIllustration';
import Process from '../partials/Process';
import Tabs from '../partials/Tabs';
import Target from '../partials/Target';
import Team from '../partials/Team';
import Legal from '../partials/Legal';

function Home() {
  return (

    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  layout.js with header + footer resolved in gatsby-config.js */}
      {/*  Page sections */}
      <PageIllustration />
      <HeroHome />
      <Target />
      <Process />
      <Tabs />
      <Team />
      <Legal />
    </div>

  );
}

export default Home;
