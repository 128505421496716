import React from 'react';
import LinkCustom from '../components/TransitionLinks/LinkCustom';

function HeroHome() {

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">

        {/* Hero content */}
        <div className="relative pt-32 pb-10 mt-10 md:pt-40">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h1 mb-4" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="easeIn" data-sal-delay="100">Mezzanine Funding to companies in Oman <br />operating in the Oil and Gas industry</h1>
            <p className="text-lg text-gray-400 mb-8" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="easeIn" data-sal-delay="300">We aim to add targeted working capital to companies in Oman so that they in return can create in country value (ICV) and increase local employment and strengthen the Omani economy.</p>
            <div data-sal="slide-up" data-sal-duration="1400" data-sal-easing="easeIn" data-sal-delay="600">
              <LinkCustom url={'/contact'} effect="fade" lenght={0.3} className="btn text-white bg-purple-600 hover:bg-purple-700 w-full mb-4 sm:w-auto sm:mb-0 transition duration-150 ease-in-out">
                Contact us
              </LinkCustom>

            </div>
          </div>


        </div>

      </div>
    </section>
  );
}

export default HeroHome;
