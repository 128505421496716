import React, { useState, useRef, useEffect } from 'react';
import Transition from '../utils/Transition.js';
import Funds from "../images/svg/funds.inline.svg";
import Mechanics from "../images/svg/mechanics.inline.svg";
import Security from "../images/svg/security.inline.svg";


function Tabs() {

  const [tab, setTab] = useState(1);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px'
    }
  }

  useEffect(() => {
    heightFix()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  return (
    <section>
      <div className="tabs-section max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12" data-sal="zoom-in" data-sal-duration="500" data-sal-easing="easeIn" data-sal-delay="200">
            <h3 className="h2 mb-4">Features</h3>
          </div>

          {/* Section content */}
          <div>

            {/* Tabs buttons */}
            <div className="flex flex-wrap justify-center -m-2" data-sal="slide-down" data-sal-duration="500" data-sal-easing="easeIn" data-sal-delay="200">
              <button
                className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${tab !== 1 && 'opacity-50'}`}
                onClick={() => setTab(1)}>
                <Mechanics className="w-4 h-4 fill-current text-purple-600 mr-2" />
                <span className="text-gray-400 group-hover:text-gray-200 transition-colors duration-150 ease-in-out">Mechanics</span>
              </button>
              <button
                className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${tab !== 2 && 'opacity-50'}`}
                onClick={() => setTab(2)}>
                <Funds className="w-4 h-4 fill-current text-purple-600 mr-2" />
                <span className="text-gray-400 group-hover:text-gray-200 transition-colors duration-150 ease-in-out">Use of funds</span>
              </button>
              <button
                className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${tab !== 3 && 'opacity-50'}`}
                onClick={() => setTab(3)}>
                <Security className="w-4 h-4 fill-current text-purple-600 mr-2" />
                <span className="text-gray-400 group-hover:text-gray-200 transition-colors duration-150 ease-in-out">Security</span>
              </button>
            </div>

            {/* Tabs items */}
            <div className="max-w-3xl flex flex-wrap mx-auto relative mt-16" ref={tabs} data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeIn" data-sal-delay="200">

              {/* Item 1 */}
              <Transition
                show={tab === 1}
                appear={true}
                className="w-full flex flex-col"
                enter="transition ease-in-out duration-500 transform order-first"
                enterStart="opacity-0 scale-98"
                enterEnd="opacity-100 scale-100"
                leave="transition ease-out duration-300 transform absolute"
                leaveStart="opacity-100 scale-100"
                leaveEnd="opacity-0 scale-98"
              >
                <article className="relative max-w-md mx-auto md:max-w-none">
                  <div className="relative bg-gray-800 py-8 md:py-16 px-6 md:pl-16">
                    <h4 className="h4 mb-5">Mechanics</h4>
                     <ul className="text-lg text-gray-400">
                       <li>Quarterly interest payments end of each calendar quarter​</li>
                       <li>ndicative Interest rate is subject to balance sheet rated credit spread</li>
                       <li>Good ratings lead to lower interest payments</li>
                     </ul>
                    <a className="btn text-white bg-purple-600 hover:bg-purple-700 w-full mb-4 mt-8 sm:w-auto sm:mb-0" href="/contact">Contact us</a>
                  </div>
                </article>
              </Transition>

              {/* Item 2 */}
              <Transition
                show={tab === 2}
                appear={true}
                className="w-full flex flex-col"
                enter="transition ease-in-out duration-500 transform order-first"
                enterStart="opacity-0 scale-98"
                enterEnd="opacity-100 scale-100"
                leave="transition ease-out duration-300 transform absolute"
                leaveStart="opacity-100 scale-100"
                leaveEnd="opacity-0 scale-98"
              >
                <article className="relative max-w-md mx-auto md:max-w-none">
                  <div className="relative bg-gray-800 py-8 md:py-16 px-6 md:pl-16">
                    <h4 className="h4 mb-5">Use of funds</h4>
                     <ul className="text-lg text-gray-400">
                       <li>Discretionary for Working Capital, Investments, Acquisitions, Buildings, Inventory, Equipment etc.</li>
                       <li>As required to execute Oil and Gas related contracts with PDO and IOCs​ ​</li>
                     </ul>
                    <a className="btn text-white bg-purple-600 hover:bg-purple-700 w-full mb-4 mt-8 sm:w-auto sm:mb-0" href="/contact">Contact us</a>
                  </div>
                </article>
              </Transition>

              {/* Item 3 */}
              <Transition
                show={tab === 3}
                appear={true}
                className="w-full flex flex-col"
                enter="transition ease-in-out duration-500 transform order-first"
                enterStart="opacity-0 scale-98"
                enterEnd="opacity-100 scale-100"
                leave="transition ease-out duration-300 transform absolute"
                leaveStart="opacity-100 scale-100"
                leaveEnd="opacity-0 scale-98"
              >
                <article className="relative max-w-md mx-auto md:max-w-none">
                  <div className="relative bg-gray-800 py-8 md:py-16 px-6 md:pl-16">
                    <h4 className="h4 mb-5">Security</h4>
                    <ul className="text-lg text-gray-400">
                      <li>Payment through escrow managed by Omani escrow agent (MeLevCo LLC in formation)​</li>
                      <li>Assignment of proceeds registered with courts from PDO, Other IOCs​</li>
                      <li>Personal guarantees and post dated checks from entrepreneurs / business owners</li>
                      <li>Others to be agreed​​</li>
                    </ul>
                    <a className="btn text-white bg-purple-600 hover:bg-purple-700 w-full mb-4 mt-8 sm:w-auto sm:mb-0" href="/contact">Contact us</a>
                  </div>
                </article>
              </Transition>

            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default Tabs;
